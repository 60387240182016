export const _modelOptions = [
  { value: 'gpt-3.5-turbo', label: 'GPT-3.5 Turbo' },
  { value: 'gpt-3.5-turbo-16k', label: 'GPT-3.5 Turbo 16k' },
  { value: 'gpt-3.5-turbo-1106', label: 'GPT-3.5 Turbo 1106' },
  { value: 'gpt-3.5-turbo-0125', label: 'GPT-3.5 Turbo 0125' },
  { value: 'gpt-3.5-turbo-instruct', label: 'GPT-3.5 Turbo Instruct' },
  { value: 'gpt-3.5-turbo-instruct-0914', label: 'GPT-3.5 Turbo Instruct 0914' },
  { value: 'gpt-3.5-turbo-0301', label: 'GPT-3.5 Turbo 0301' },
  { value: 'gpt-3.5-turbo-0613', label: 'GPT-3.5 Turbo 0613' },
  { value: 'gpt-3.5-turbo-16k-0613', label: 'GPT-3.5 Turbo 16k 0613' },
  { value: 'gpt-4', label: 'GPT-4' },
  { value: 'gpt-4-0613', label: 'GPT-4 0613' },
  { value: 'gpt-4-32k-0314', label: 'GPT-4 32k 0314' },
  { value: 'gpt-4-turbo', label: 'GPT-4 Turbo' },
  { value: 'gpt-4-turbo-2024-04-09', label: 'GPT-4 Turbo 2024-04-09' },
  { value: 'gpt-4-turbo-preview', label: 'GPT-4 Turbo Preview' },
  { value: 'gpt-4-1106-preview', label: 'GPT-4 1106 Preview' },
  { value: 'gpt-4-vision-preview', label: 'GPT-4 Vision Preview' },
  { value: 'gpt-4-0125-preview', label: 'GPT-4 0125 Preview' },
  { value: 'gpt-4o', label: 'GPT-4o' },
  { value: 'gpt-4o-2024-05-13', label: 'GPT-4o 2024-05-13' },
  { value: 'gpt-4o-2024-08-06', label: 'GPT-4o 2024-08-06' },
  { value: 'gpt-4o-mini', label: 'GPT-4o Mini' },
  { value: 'gpt-4o-mini-2024-07-18', label: 'GPT-4o Mini 2024-07-18' },
  { value: 'chatgpt-4o-latest', label: 'ChatGPT-4o Latest' },
  { value: 'anthropic/claude-instant-1.2', label: 'Claude Instant 1.2' },
  { value: 'anthropic/claude-3-opus', label: 'Claude 3 Opus' },
  { value: 'anthropic/claude-3-haiku', label: 'Claude 3 Haiku' },
  { value: 'anthropic/claude-3.5-sonnet', label: 'Claude 3 5 Sonnet' },
  { value: 'gryphe/mythomax-l2-13b', label: 'MythoMax 13B' },
  { value: 'microsoft/wizardlm-2-8x22b', label: 'MS WizardLM-2 8x22B' },
  { value: 'deepseek/deepseek-coder', label: 'DeepSeek-Coder-V2' },
  { value: 'mistralai/mistral-nemo', label: 'Mistral Nemo' },
  { value: 'mistralai/mixtral-8x7b-instruct', label: 'Mixtral 8x7B Instruct' },
  { value: 'meta-llama/llama-3.1-70b-instruct', label: 'Meta LLAMA 3.1 70B Instruct' },
  { value: 'google/gemini-flash-1.5', label: 'Google Gemini Flash 1.5' },
  { value: 'nousresearch/hermes-3-llama-3.1-405b', label: 'Hermes 3 LLAMA 3.1 405B' },
];
