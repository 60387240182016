import { createSlice } from '@reduxjs/toolkit';
import { default as customAxios } from 'axios';
import { saveAs } from 'file-saver';

import { OPENAI_API_KEY, SOFIA_API } from '../../config';
import axios from '../../utils/axios';
import { getProjectFromFirestore } from '../../utils/firestore';

const initialState = {
  isLoading: false,
  error: null,
  questions: [],
  isDeleting: false,
  isBatchCreating: false,
  isAsking: false,
  operationProgress: 0,
  isUploading: false,
  uploadStatus: '',
  isFineTuning: false,
  fineTuningStatus: '',
  project: null,
};

const slice = createSlice({
  name: 'question',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    startAsking(state) {
      state.isAsking = true;
      state.operationProgress = 0;
    },
    updateAskingProgress(state, action) {
      state.operationProgress = action.payload;
    },
    receiveGPTAnswer(state, action) {
      state.isAsking = false;
      state.operationProgress = 100;
      const currentQuestion = state.questions.find((q) => q.id === action.payload.id);
      if (currentQuestion) {
        currentQuestion.answer = action.payload.answer;
      }
    },
    getQuestionsSuccess(state, action) {
      state.isLoading = false;
      state.questions = action.payload.questions;
    },
    editQuestionSuccess(state, action) {
      state.isLoading = false;
      const editedQuestion = action.payload.question;
      const index = state.questions.findIndex((q) => q.id === editedQuestion.id);
      if (index !== -1) {
        state.questions[index] = editedQuestion;
      }
    },
    startDeleting(state) {
      state.isDeleting = true;
      state.operationProgress = 0;
    },
    updateDeletingProgress(state, action) {
      state.operationProgress = action.payload;
    },
    deleteQuestionSuccess(state, action) {
      state.isDeleting = false;
      state.questions = state.questions.filter((q) => !action.payload.questionIds.includes(q.id));
      state.operationProgress = 100;
    },
    addQuestionSuccess(state, action) {
      state.isLoading = false;
      state.questions.push(action.payload.question);
    },
    startBatchCreating(state) {
      state.isBatchCreating = true;
      state.operationProgress = 0;
    },
    updateBatchCreatingProgress(state, action) {
      state.operationProgress = action.payload;
    },
    batchCreateQuestionsSuccess(state, action) {
      state.isBatchCreating = false;
      state.questions = [...state.questions, ...action.payload.questions];
      state.operationProgress = 100;
    },
    startUploading(state) {
      state.isUploading = true;
      state.uploadStatus = 'Uploading file...';
    },
    uploadSuccess(state, action) {
      state.isUploading = false;
      state.uploadStatus = `File uploaded successfully. File ID: ${action.payload}`;
    },
    uploadError(state, action) {
      state.isUploading = false;
      state.uploadStatus = `Error uploading file: ${action.payload}`;
    },
    startFineTuning(state) {
      state.isFineTuning = true;
      state.fineTuningStatus = 'Creating fine-tuning job...';
    },
    fineTuningSuccess(state, action) {
      state.isFineTuning = false;
      state.fineTuningStatus = `Fine-tuning job created successfully. Job ID: ${action.payload}`;
    },
    fineTuningError(state, action) {
      state.isFineTuning = false;
      state.fineTuningStatus = `Error creating fine-tuning job: ${action.payload}`;
    },
    setProjectDetails(state, action) {
      state.project = action.payload;
    },
  },
});

export const {
  startLoading,
  hasError,
  startAsking,
  updateAskingProgress,
  receiveGPTAnswer,
  getQuestionsSuccess,
  editQuestionSuccess,
  deleteQuestionSuccess,
  addQuestionSuccess,
  batchCreateQuestionsSuccess,
  startDeleting,
  startBatchCreating,
  updateDeletingProgress,
  updateBatchCreatingProgress,
  startUploading,
  uploadSuccess,
  uploadError,
  startFineTuning,
  fineTuningSuccess,
  fineTuningError,
  setProjectDetails,
} = slice.actions;

export default slice.reducer;

// Helper function to get CurrentProject header value
const getCurrentProject = () => localStorage.getItem('currentProject');

const getCurrenUser = () => localStorage.getItem('accountUid');

// Helper function to clean up markdown
const cleanupMarkdown = (text) => {
  if (!text) {
    return '';
  }

  // Remove code blocks
  text = text.replace(/```[\s\S]*?```/g, '');

  // Remove inline code
  text = text.replace(/`([^`]+)`/g, '$1');

  // Remove headers
  text = text.replace(/^#{1,6}\s+/gm, '');

  // Remove bold and italic
  text = text.replace(/(\*\*|__)(.*?)\1/g, '$2');
  text = text.replace(/(\*|_)(.*?)\1/g, '$2');

  // Remove links but keep the text
  text = text.replace(/\[([^\]]+)\]\([^\)]+\)/g, '$1');

  // Remove images
  text = text.replace(/!\[([^\]]+)\]\([^\)]+\)/g, '');

  // Remove blockquotes
  text = text.replace(/^>\s+/gm, '');

  // Remove horizontal rules
  text = text.replace(/^(-{3,}|\*{3,})$/gm, '');

  // Remove HTML tags
  text = text.replace(/<[^>]*>/g, '');

  // Remove extra whitespace
  text = text.replace(/\s+/g, ' ').trim();

  return text;
};

export const askGPT = (questionData) => async (dispatch) => {
  dispatch(startAsking());
  try {
    const conversationId = localStorage.getItem('currentConversationKey');
    const projectId = getCurrentProject();
    const { question: message, id } = questionData;

    // Simulate progress updates
    for (let i = 0; i <= 90; i += 10) {
      await new Promise((resolve) => setTimeout(resolve, 500));
      dispatch(updateAskingProgress(i));
    }

    const response = await axios.post(
      `${SOFIA_API}/api/chat/ask-gpt`,
      {
        question: message,
        conversation_id: conversationId,
      },
      {
        headers: {
          CurrentProject: projectId,
        },
      }
    );

    let { answer } = response.data;
    answer = answer.split('<div class="sources-wrapper">', 1)[0];

    dispatch(receiveGPTAnswer({ id, answer }));
    return { id, answer }; // Return the answer
  } catch (error) {
    dispatch(hasError(error.message || 'Failed to ask GPT.'));
    throw error; // Re-throw the error to be caught in the component
  }
};

export const batchCreateQuestions = () => async (dispatch) => {
  dispatch(startBatchCreating());
  try {
    const projectId = getCurrentProject();

    // Simulate progress updates
    for (let i = 0; i <= 100; i += 10) {
      await new Promise((resolve) => setTimeout(resolve, 500));
      dispatch(updateBatchCreatingProgress(i));
    }

    const response = await axios.post(
      `${SOFIA_API}/api/question/batch/create`,
      {},
      {
        headers: {
          CurrentProject: projectId,
        },
      }
    );
    dispatch(batchCreateQuestionsSuccess(response.data));
  } catch (error) {
    dispatch(hasError(error.message || 'Failed to batch create questions.'));
  }
};

export const deleteQuestion = (questionIds) => async (dispatch) => {
  dispatch(startDeleting());
  try {
    const projectId = getCurrentProject();

    // Simulate progress updates
    for (let i = 0; i <= 100; i += 10) {
      await new Promise((resolve) => setTimeout(resolve, 500));
      dispatch(updateDeletingProgress(i));
    }

    await axios.delete('/api/question/delete', {
      data: { question_ids: questionIds },
      headers: {
        CurrentProject: projectId,
      },
    });
    dispatch(deleteQuestionSuccess({ questionIds }));
    await dispatch(getQuestions());
  } catch (error) {
    dispatch(hasError(error.message || 'Failed to delete question(s).'));
  }
};

export const getQuestions = () => async (dispatch) => {
  dispatch(startLoading());
  try {
    const projectId = getCurrentProject();
    const response = await axios.post(
      `${SOFIA_API}/api/question/list`,
      {},
      {
        headers: {
          CurrentProject: projectId,
        },
      }
    );
    dispatch(getQuestionsSuccess(response.data));
  } catch (error) {
    dispatch(hasError(error.message || 'Failed to fetch questions.'));
  }
};

export const editQuestion = (question) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const projectId = getCurrentProject();
    const { data } = await axios.put(
      '/api/question/edit',
      { question },
      {
        headers: {
          CurrentProject: projectId,
        },
      }
    );
    dispatch(editQuestionSuccess(data));
  } catch (error) {
    dispatch(hasError(error.message || 'Failed to edit question.'));
  }
};

export const addQuestion = (question) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const projectId = getCurrentProject();
    const response = await axios.post(
      '/api/question/add',
      { question },
      {
        headers: {
          CurrentProject: projectId,
        },
      }
    );
    dispatch(addQuestionSuccess(response.data));
  } catch (error) {
    dispatch(hasError(error.message || 'Failed to add question.'));
  }
};

export const uploadFile = (file) => async (dispatch, getState) => {
  dispatch(startUploading());
  const formData = new FormData();
  formData.append('file', file);
  formData.append('purpose', 'fine-tune');

  try {
    const response = await customAxios.post('https://api.openai.com/v1/files', formData, {
      headers: {
        Authorization: `Bearer ${OPENAI_API_KEY}`,
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.data.id) {
      dispatch(uploadSuccess(response.data.id));
      dispatch(createFineTuningJob(response.data.id));
    }
  } catch (error) {
    console.error('Error uploading file:', error);
    dispatch(uploadError(error.message || 'Error uploading file. Please try again.'));
  }
};

export const createFineTuningJob = (fileId) => async (dispatch, getState) => {
  dispatch(startFineTuning());
  try {
    const state = getState();
    const { project } = state.question;

    if (!project || !project.model_name_answers) {
      throw new Error('Project details or model name not available');
    }

    const response = await customAxios.post(
      'https://api.openai.com/v1/fine_tuning/jobs',
      {
        training_file: fileId,
        model: project.model_name_answers,
      },
      {
        headers: {
          Authorization: `Bearer ${OPENAI_API_KEY}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.data.id) {
      dispatch(fineTuningSuccess(response.data.id));
    }
  } catch (error) {
    console.error('Error creating fine-tuning job:', error);
    dispatch(fineTuningError(error.message || 'Error creating fine-tuning job. Please try again.'));
  }
};

// New action to fetch project details
export const fetchProjectDetails = () => async (dispatch) => {
  try {
    const projectId = getCurrentProject();
    const uid = getCurrenUser();
    const response = await getProjectFromFirestore(uid, projectId);
    dispatch(setProjectDetails(response));
  } catch (error) {
    console.error('Error fetching project details:', error);
    dispatch(hasError(error.message || 'Failed to fetch project details.'));
  }
};

// Update the exportToJSONL function
export const exportToJSONL = (data) => async (dispatch, getState) => {
  const state = getState();
  const { project } = state.question;

  if (!project || !project.answers_master_prompt) {
    console.error('Project details or master prompt not available');
    return;
  }

  const cleanedMasterPrompt = cleanupMarkdown(project.answers_master_prompt);

  const jsonl = data
    .map((item) => {
      const cleanedAnswer = cleanupMarkdown(item.answer);
      return JSON.stringify({
        messages: [
          { role: 'system', content: cleanedMasterPrompt },
          { role: 'user', content: item.question },
          { role: 'assistant', content: cleanedAnswer },
        ],
      });
    })
    .join('\n');

  const blob = new Blob([jsonl], { type: 'application/x-jsonlines' });
  saveAs(blob, `${localStorage.getItem('currentConversationName').replace(/ /g, '_')}-training-dataset.jsonl`);
};
