import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { _modelOptions } from '../../_mock/_modelOptions';
import { OPENAI_API_KEY } from '../../config';
import axios from '../../utils/axios';
import {
  getProjectFromFirestore,
  getProjectKeysFromFirestore,
  getProjectsFromFirestore,
  getProjectVersionFromFirestore,
  getProjectVersionsFromFirestore,
  renameProjectVersionInFirestore,
  saveProjectKeysInFirestore,
  saveProjectToFirestore,
  saveProjectVersionToFirestore,
} from '../../utils/firestore';
import uuidv4 from '../../utils/uuidv4';
import { dispatch } from '../store';

const { REACT_APP_SOFIA_API } = process.env;

const initialState = {
  isLoading: false,
  error: null,
  projects: [],
  project: null,
  sortBy: null,
  currentProjectKeys: null,
  projectVersions: [],
  currentVersion: null,
  fineTunedModels: [],
  checkout: {
    activeStep: 0,
    subtotal: 0,
    total: 0,
    discount: 0,
  },
};

const slice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    renameProjectVersionSuccess(state, action) {
      const { versionId, newName } = action.payload;
      state.projectVersions = state.projectVersions.map((version) => (version.id === versionId ? { ...version, name: newName } : version));
    },

    saveProjectVersionSuccess(state, action) {
      state.isLoading = false;
      state.projectVersions.push(action.payload);
      state.currentVersion = action.payload;
    },

    getProjectVersionsSuccess(state, action) {
      state.isLoading = false;
      state.projectVersions = action.payload;
    },

    setCurrentProjectVersion(state, action) {
      state.currentVersion = action.payload;
    },

    getProjectsSuccess(state, action) {
      state.isLoading = false;
      state.projects = action.payload;
    },

    getProjectSuccess(state, action) {
      state.isLoading = false;
      state.projects = action.payload;
    },

    removeFileSuccess(state, action) {
      state.projects = action.payload;
    },

    saveProjectSuccess(state, action) {
      state.isLoading = false;
      state.projects = action.payload;
    },

    saveDefaultProjectSuccess(state, action) {
      state.isLoading = false;
    },

    fileUploadStarted(state, action) {
      state.isLoading = false;
      state.projects = action.payload;
    },

    fileUploadSuccess(state, action) {
      state.isLoading = false;
      state.projects = action.payload;
    },

    deleteProjectSuccess(state, action) {
      state.isLoading = false;
      state.projects = action.payload;
    },

    deleteFileSuccess(state, action) {
      state.isLoading = false;
      state.projects = action.payload;
    },

    sortByProjects(state, action) {
      state.sortBy = action.payload;
    },

    saveProjectKeysSuccess(state, action) {
      state.isLoading = false;
      const { projectId, keys } = action.payload;
      state.projects = state.projects.map((project) => (project.id === projectId ? { ...project, keys } : project));
      state.currentProjectKeys = keys;
    },

    fetchProjectKeysSuccess(state, action) {
      state.isLoading = false;
      state.currentProjectKeys = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFineTunedModels.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchFineTunedModels.fulfilled, (state, action) => {
        state.isLoading = false;
        state.fineTunedModels = action.payload;
      })
      .addCase(fetchFineTunedModels.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { sortByProjects, removeFileSuccess, fetchProjectKeysSuccess, setCurrentProjectVersion, renameProjectVersionSuccess } = slice.actions;

const getStatusInfo = (status) => {
  switch (status) {
    case 'succeeded':
      return { icon: 'mdi:check-circle', color: 'success.main' };
    case 'failed':
      return { icon: 'mdi:alert-circle', color: 'error.main' };
    case 'cancelled':
      return { icon: 'mdi:cancel', color: 'warning.main' };
    case 'running':
      return { icon: 'mdi:progress-clock', color: 'info.main' };
    case 'queued':
      return { icon: 'mdi:clock-outline', color: 'info.light' };
    case 'validating_files':
      return { icon: 'mdi:file-check', color: 'info.dark' };
    default:
      return { icon: 'mdi:help-circle', color: 'text.secondary' };
  }
};

export const fetchFineTunedModels = createAsyncThunk('project/fetchFineTunedModels', async (_, { rejectWithValue, getState }) => {
  try {
    const jobsResponse = await fetch('https://api.openai.com/v1/fine_tuning/jobs?limit=100', {
      headers: {
        Authorization: `Bearer ${OPENAI_API_KEY}`,
        'Content-Type': 'application/json',
      },
    });
    const jobsData = await jobsResponse.json();

    const modelsWithFiles = await Promise.all(
      jobsData.data.map(async (job) => {
        if (job.training_file) {
          const fileResponse = await fetch(`https://api.openai.com/v1/files/${job.training_file}`, {
            headers: {
              Authorization: `Bearer ${OPENAI_API_KEY}`,
              'Content-Type': 'application/json',
            },
          });
          const fileData = await fileResponse.json();
          const statusInfo = getStatusInfo(job.status);
          const baseModel = _modelOptions.find((option) => option.value === job.model);
          const readableFileName = fileData.filename.split('.')[0].replace(/_/g, ' ');
          return {
            ...job,
            training_file_name: fileData.filename,
            readable_name: baseModel ? `${baseModel.label} (${readableFileName})` : `${job.model} (${readableFileName})`,
            disabled: job.status !== 'succeeded',
            statusIcon: statusInfo.icon,
            statusColor: statusInfo.color,
            error: job.error ? job.error.message : null,
          };
        }
        return job;
      })
    );

    return modelsWithFiles;
  } catch (error) {
    console.error('Error fetching fine-tuned models:', error);
    return rejectWithValue(error.message);
  }
});

export function renameProjectVersion(projectId, versionId, newName) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const uid = localStorage.getItem('accountUid');
      await renameProjectVersionInFirestore(uid, projectId, versionId, newName);
      dispatch(renameProjectVersionSuccess({ versionId, newName }));
    } catch (error) {
      console.error('Error renaming project version:', error);
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function saveProjectVersion(projectId, versionData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const uid = localStorage.getItem('accountUid');
      const versionId = uuidv4();
      const timestamp = new Date().toISOString();

      const versionToSave = {
        id: versionId,
        timestamp,
        ...versionData,
      };

      await saveProjectVersionToFirestore(uid, projectId, versionToSave);
      dispatch(slice.actions.saveProjectVersionSuccess(versionToSave));
    } catch (error) {
      console.log('There was an error saving the project version', error);
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function getProjectVersions(projectId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const uid = localStorage.getItem('accountUid');
      const versions = await getProjectVersionsFromFirestore(uid, projectId);
      dispatch(slice.actions.getProjectVersionsSuccess(versions));
    } catch (error) {
      console.log('There was an error fetching project versions', error);
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function loadProjectVersion(projectId, versionId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const uid = localStorage.getItem('accountUid');
      const version = await getProjectVersionFromFirestore(uid, projectId, versionId);
      dispatch(slice.actions.setCurrentProjectVersion(version));
    } catch (error) {
      console.log('There was an error loading the project version', error);
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function getProjects() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const uid = localStorage.getItem('accountUid');
      const projects = await getProjectsFromFirestore(uid);
      dispatch(slice.actions.getProjectsSuccess(projects));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function searchProject(text) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const uid = localStorage.getItem('accountUid');
      const projects = await getProjectsFromFirestore(uid);
      const filteredProjects = projects.filter((project) => project.name.toLowerCase().includes(text.toLowerCase()));
      dispatch(slice.actions.getProjectSuccess(filteredProjects));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function saveProjectKeys({ project, keys }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const uid = localStorage.getItem('accountUid');

      // Save keys in the project's keys collection
      const updatedKeys = await saveProjectKeysInFirestore(uid, project.id, keys);

      dispatch(slice.actions.saveProjectKeysSuccess({ projectId: project.id, keys: updatedKeys }));
    } catch (error) {
      console.log('There was an error saving the project keys', error);
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function fetchProjectKeys(projectId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const uid = localStorage.getItem('accountUid');
      const keys = await getProjectKeysFromFirestore(uid, projectId);
      dispatch(slice.actions.fetchProjectKeysSuccess(keys));
    } catch (error) {
      console.log('There was an error fetching the project keys', error);
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function saveProject(data) {
  return async () => {
    try {
      const uid = localStorage.getItem('accountUid');

      await saveProjectToFirestore(uid, data);

      const projects = await getProjectsFromFirestore(uid);

      dispatch(slice.actions.getProjectsSuccess(projects));
    } catch (error) {
      console.log('There was an error saving the project', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteProject(projectsToDelete) {
  return async () => {
    const uid = localStorage.getItem('accountUid');
    const projectId = projectsToDelete[0].id;
    const documentIds = projectsToDelete[0].files.map((file) => file?.id ?? 0);
    try {
      const additionalHeaders = {
        CurrentProject: `${projectId}`,
      };
      const headers = {
        ...axios.defaults.headers.common,
        ...additionalHeaders,
      };

      const URL = `${REACT_APP_SOFIA_API}/api/project/delete`;

      dispatch(slice.actions.startLoading());

      const result = await axios.delete(URL, {
        headers: headers,
        data: {
          ids: documentIds,
          uid,
          filter: {
            chain: projectId,
          },
        },
        timeout: 0,
      });

      dispatch(slice.actions.deleteProjectSuccess(result.data.projects));
    } catch (error) {
      console.log('There was an error deleting the project', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteFiles(projectId, remainingFiles, deleteQuestions) {
  return async () => {
    const uid = localStorage.getItem('accountUid');
    let idsToDelete = [];
    let savedFiles = [];
    try {
      const idsToHold = remainingFiles.map((file) => file?.id ?? 0);

      const project = await getProjectFromFirestore(uid, projectId);
      if (project) {
        // @ts-ignore
        savedFiles = project.files.length > 0 ? project.files : [];
        idsToDelete = savedFiles.filter((file) => !idsToHold.includes(file.id)).map((file) => file?.id ?? 0);
      }
      console.log('shouldDeleteQuestions', deleteQuestions);
      const additionalHeaders = {
        CurrentProject: `${projectId}`,
      };
      const headers = {
        ...axios.defaults.headers.common,
        ...additionalHeaders,
      };
      const URL = `${REACT_APP_SOFIA_API}/api/project/files/delete`;
      dispatch(slice.actions.startLoading());
      if (idsToDelete.length === 0) {
        alert('No ids to delete');
        return;
      }
      const response = await axios.delete(URL, {
        headers: headers,
        data: {
          ids: idsToDelete,
          deleteQuestions: deleteQuestions,
          filter: {
            chain: projectId,
          },
        },
      });
      dispatch(slice.actions.deleteFileSuccess(response.data.projects));
    } catch (error) {
      console.log('There was an error deleting the files', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
