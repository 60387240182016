import { collection, deleteDoc, doc, getDoc, getDocs, orderBy, query, setDoc, updateDoc } from 'firebase/firestore';

import { DB } from '../contexts/FirebaseContext';
import uuidv4 from './uuidv4';

export const USER_CONTACT_STUB = {
  id: '',
  avatar: '',
  name: '',
  username: '',
  lastActivity: new Date().toISOString(),
  status: 'online',
  position: 'user',
};

export const SOFIA_CONTACTS_STUB = [
  {
    id: '',
    avatar: '',
    name: '',
    username: '',
    lastActivity: '',
    status: 'online',
    position: 'agent',
    telephonyAppUrl: '',
    voiceAppUrl: '',
  },
];

export const CONVERSATIONS_STUB = [
  {
    id: '',
    name: '',
    participants: [USER_CONTACT_STUB, SOFIA_CONTACTS_STUB[0]],
    type: 'ONE_TO_ONE',
    unreadCount: 0,
    projectId: '',
    createdAt: '',
    telephonyAppUrl: '',
    voiceAppUrl: '',
    messages: [
      {
        id: uuidv4(),
        body: 'sofia_default',
        contentType: 'text',
        attachments: [],
        createdAt: '',
        senderId: '',
      },
    ],
  },
];

export async function renameProjectVersionInFirestore(uid, projectId, versionId, newName) {
  const versionRef = doc(DB, 'users', uid, 'projects', projectId, 'versions', versionId);
  await updateDoc(versionRef, { name: newName });
}

export async function saveProjectVersionToFirestore(uid, projectId, versionData) {
  const versionRef = doc(DB, 'users', uid, 'projects', projectId, 'versions', versionData.id);
  await setDoc(versionRef, { ...versionData, name: versionData.name || new Date().toLocaleString() });
}

export async function getProjectVersionsFromFirestore(uid, projectId) {
  const versionsRef = collection(DB, 'users', uid, 'projects', projectId, 'versions');
  const q = query(versionsRef, orderBy('timestamp', 'desc'));
  const snapshot = await getDocs(q);
  return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
}

export async function getProjectVersionFromFirestore(uid, projectId, versionId) {
  const versionRef = doc(DB, 'users', uid, 'projects', projectId, 'versions', versionId);
  const snapshot = await getDoc(versionRef);
  if (snapshot.exists()) {
    return { id: snapshot.id, ...snapshot.data() };
  }
  return null;
}

export const getProjectsFromFirestore = async (uid) => {
  const userProjectsSnap = collection(DB, `users/${uid}/projects`);
  const projectsData = await getDocs(userProjectsSnap);
  const projects = [];
  projectsData.forEach((doc) => {
    projects.push(doc.data());
  });
  return projects;
};

export const saveProjectKeysInFirestore = async (uid, projectId, keys) => {
  const currentDate = new Date().toISOString();

  const keysCollectionRef = collection(DB, `users/${uid}/projects/${projectId}/keys`);

  for (const [keyName, keyValue] of Object.entries(keys)) {
    const keyDocRef = doc(keysCollectionRef, keyName.toLowerCase());

    await setDoc(
      keyDocRef,
      {
        value: keyValue,
        updatedAt: currentDate,
      },
      { merge: true }
    );
  }

  return await getProjectKeysFromFirestore(uid, projectId);
};

export const getProjectKeysFromFirestore = async (uid, projectId) => {
  const keysCollectionRef = collection(DB, `users/${uid}/projects/${projectId}/keys`);
  const keysSnapshot = await getDocs(keysCollectionRef);

  const keys = {};
  keysSnapshot.forEach((doc) => {
    keys[doc.id] = doc.data().value;
  });

  return keys;
};

export const saveProjectToFirestore = async (uid, data) => {
  let status = 'success';
  let projectRef;
  const project = {};

  if (!data) {
    throw new Error('Invalid project data');
  }

  const currentDate = new Date().toISOString();

  if (data.id) {
    projectRef = doc(collection(DB, `users/${uid}/projects`), data.id);
    project.updatedAt = currentDate;

    if (data.isAdmin) {
      project.chunk_questions = data.chunk_questions;
      project.query_top_k = data.query_top_k;
      project.chunk_size = data.chunk_size;
      project.chunk_overlap = data.chunk_overlap;
      project.temperature_questions = data.temperature_questions;
      project.temperature_answers = data.temperature_answers;
      project.qa_language = data.qa_language;
      project.max_tokens_answers = data.max_tokens_answers;
      project.model_name_questions = data.model_name_questions;
      project.model_name_embeddings = data.model_name_embeddings;
      project.model_name_answers = data.model_name_answers;
      project.hybrid_convex_alpha = data.hybrid_convex_alpha;
      project.voice_id_questions = data.voice_id_questions;
      project.voice_id_answers = data.voice_id_answers;
      project.questions_master_prompt = data.questions_master_prompt;
      project.answers_master_prompt = data.answers_master_prompt;
      project.telephony_app_url = data.telephony_app_url;
      project.voice_app_url = data.voice_app_url;
    }
  } else {
    projectRef = doc(collection(DB, 'users', uid, 'projects'));
    project.createdAt = currentDate;
    project.status = status;
    project.files = [];
    project.tags = [];
    project.num_pages = 0;
    project.num_words = 0;
    project.num_chunks = 0;
    project.num_tokens = 0;
    project.num_questions = 0;
    project.num_answers = 0;
    project.num_files = 0;
    project.num_pending_files = 0;
    project.num_completed_files = 0;
    project.num_failed_files = 0;
    project.num_conversations = 0;

    if (data.isAdmin) {
      const basePrompts = await getBasePromptsFromFirestore(uid);
      project.questions_master_prompt = basePrompts.questions_master_prompt?.prompt;
      project.answers_master_prompt = basePrompts.answers_master_prompt?.prompt;
      project.chunk_questions = data.chunk_questions;
      project.query_top_k = data.query_top_k;
      project.chunk_size = data.chunk_size;
      project.chunk_overlap = data.chunk_overlap;
      project.temperature_questions = data.temperature_questions;
      project.temperature_answers = data.temperature_answers;
      project.qa_language = data.qa_language;
      project.max_tokens_answers = data.max_tokens_answers;
      project.model_name_questions = data.model_name_questions;
      project.model_name_answers = data.model_name_answers;
      project.model_name_embeddings = data.model_name_embeddings;
      project.hybrid_convex_alpha = data.hybrid_convex_alpha;
      project.voice_id_questions = data.voice_id_questions;
      project.voice_id_answers = data.voice_id_answers;
      project.telephony_app_url = data.telephony_app_url;
      project.voice_app_url = data.voice_app_url;
    }
  }

  project.id = projectRef.id;
  project.name = data.name;
  project.description = data.description;
  project.isDefaultProject = data.isDefaultProject;
  project.qa_language = data.qa_language;

  await setDoc(projectRef, project, { merge: true });
};

export const newProjectInFirestore = async (uid, project) => {
  const projectRef = doc(collection(DB, 'users', uid, 'projects'));
  await setDoc(projectRef, project, { merge: true });
  return project;
};

export const getBasePromptsFromFirestore = async (uid) => {
  const basePromptsSnap = collection(DB, `users/${uid}/base_prompts`);
  const basePrompts = await getDocs(basePromptsSnap);
  const prompts = {};
  basePrompts.forEach((doc) => {
    prompts[doc.id] = doc.data();
  });
  return prompts;
};

export const getProjectFromFirestore = async (uid, projectId) => {
  const projectRef = doc(DB, `users/${uid}/projects`, projectId);
  const projectSnap = await getDoc(projectRef);
  return projectSnap.exists() ? projectSnap.data() : null;
};

export const updateProjectInFirestore = async (uid, projectId, projectData) => {
  const projectRef = doc(DB, `users/${uid}/projects`, projectId);
  await setDoc(projectRef, projectData, { merge: true });
};

export const getUserInFirestore = async (uid) => {
  const snapshot = doc(DB, 'users', uid);
  const user = await getDoc(snapshot);
  return user.data();
};

export const getUserByCustomerIdInFirestore = async (customerId) => {
  const snapshot = collection(DB, 'users');
  const usersData = await getDocs(snapshot);
  const users = [];
  usersData.forEach((doc) => {
    users.push(doc.data());
  });
  const user = users.find((user) => user.stripeCustomerId === customerId);
  return user;
};

export const updateUserInFirestore = async (uid, data) => {
  const user = await getUserInFirestore(uid);
  const updatedUser = { ...user, ...data };
  const ref = doc(DB, 'users', uid);
  await setDoc(ref, updatedUser, { merge: true });
  return updatedUser;
};

export const updateUserByCustomerIdInFirestore = async (customerId, data) => {
  const user = await getUserByCustomerIdInFirestore(customerId);
  const updatedUser = { ...user, ...data };
  const ref = doc(DB, 'users', user.id);
  await setDoc(ref, updatedUser, { merge: true });
  return updatedUser;
};

export const getConversationByProjectInFirestore = async (uid, pid) => {
  // console.log('getConversationByProject', uid, pid);
  const snapshot = collection(DB, `users/${uid}/projects/${pid}/conversations`);
  const conversationsData = await getDocs(snapshot);
  const conversations = [];
  conversationsData.forEach((doc) => {
    conversations.push(doc.data());
  });
  return conversations;
};

export const saveNewConversationInFirestore = async (uid, pid, cid, data) => {
  const currentDate = new Date().toISOString();
  const ref = doc(collection(DB, `users/${uid}/projects/${pid}/conversations`, cid));
  const conversationSnapshot = await getDoc(ref);
  const conversation = conversationSnapshot.data();

  // Update the conversation data
  if (conversation) {
    conversation.name = data.name;
    conversation.updatedAt = currentDate;
    conversation.participants[1].name = data.name;
  }

  // Save the updated conversation back to Firestore
  if (conversation) {
    // await ref.set(conversation, { merge: true });
    await setDoc(ref, conversation, { merge: true });
  }

  return conversation;
};

export const createNewConversationInFirestore = async (uid, pid, conversation) => {
  console.log('createConversation', uid, pid, conversation);
  const ref = doc(DB, `users/${uid}/projects/${pid}/conversations`);
  const newConversation = {
    id: ref.id,
    projectId: pid,
    ...conversation,
  };

  await setDoc(ref, newConversation);

  return newConversation;
};

export const createDefaultConversationInFirestore = async (uid, user, project) => {
  const defaultConversation = CONVERSATIONS_STUB[0];

  const ref = doc(collection(DB, `users/${uid}/projects/${project.id}/conversations`));

  const telephonyAppUrl = project.telephony_app_url || project.telephonyAppUrl;
  const voiceAppUrl = project.voice_app_url || project.voiceAppUrl;

  defaultConversation.id = ref.id;
  defaultConversation.name = project.name;
  defaultConversation.projectId = project.id;
  defaultConversation.createdAt = new Date().toISOString();

  defaultConversation.participants[0].id = uid;
  defaultConversation.participants[0].name = user.name || user.email.replace(/[@*_-]/, '.');
  defaultConversation.participants[0].username = user.email.replace(/[@*_-]/, '.');
  defaultConversation.participants[0].lastActivity = new Date().toISOString();
  defaultConversation.participants[0].telephonyAppUrl = telephonyAppUrl || '';
  defaultConversation.participants[0].voiceAppUrl = voiceAppUrl || '';
  defaultConversation.participants[0].role = 'user';

  defaultConversation.participants[1].id = project.id;
  defaultConversation.participants[1].name = project.name;
  defaultConversation.participants[1].username = `agent.sofia.${project.id.substring(0, 5)}`;
  defaultConversation.participants[1].lastActivity = new Date().toISOString();
  defaultConversation.participants[1].telephonyAppUrl = telephonyAppUrl || '';
  defaultConversation.participants[1].voiceAppUrl = voiceAppUrl || '';
  defaultConversation.participants[1].role = 'agent';

  defaultConversation.messages[0].senderId = project.id;
  defaultConversation.messages[0].createdAt = new Date().toISOString();

  try {
    await setDoc(ref, defaultConversation);
  } catch (error) {
    console.error('Error creating default conversation:', error);
    throw error;
  }

  return defaultConversation;
};

// Update an existing conversation
export const updateConversationInFirestore = async (uid, pid, cid, conversation) => {
  const ref = doc(DB, `users/${uid}/projects/${pid}/conversations`, cid);
  await setDoc(ref, conversation, { merge: true });
};

// Delete a conversation

export const deleteConversationInFirestore = async (uid, pid, cid) => {
  if (!uid || !pid || !cid) {
    throw new Error('Invalid parameters: uid, pid, and cid are required');
  }

  try {
    const conversationRef = doc(DB, 'users', uid, 'projects', pid, 'conversations', cid);
    await deleteDoc(conversationRef);
    console.log(`Conversation ${cid} deleted successfully`);
  } catch (error) {
    console.error('Error deleting conversation:', error);
    throw new Error('Failed to delete conversation');
  }
};
export const deleteMessageInFirestore = async (uid, projectId, conversationId, messageId) => {
  try {
    const conversationRef = doc(DB, 'users', uid, 'projects', projectId, 'conversations', conversationId);
    const conversationDoc = await getDoc(conversationRef);

    if (conversationDoc.exists()) {
      const updatedMessages = conversationDoc.data().messages.filter((message) => message.id !== messageId);
      await updateDoc(conversationRef, { messages: updatedMessages });
    } else {
      throw new Error('Conversation not found');
    }
  } catch (error) {
    console.error('Error deleting message in Firestore:', error);
    throw error;
  }
};

export const appendMessageToConversationInFirestore = async (uid, pid, cid, message) => {
  const newMessage = {
    id: message.messageId,
    body: message.message,
    contentType: message.contentType,
    attachments: [],
    createdAt: message.createdAt,
    senderId: message.senderId,
  };

  let payload = {};

  let messageSnapshot = await getDoc(doc(DB, `users/${uid}/projects/${pid}/conversations`, cid));
  let currentMessages = messageSnapshot.data().messages;
  currentMessages.push(newMessage);

  if (message.senderId === uid) {
    payload = {
      // messages: firebaseAdmin.firestore.FieldValue.arrayUnion(newMessage),
      messages: currentMessages,
    };
  } else {
    payload = {
      // messages: firebaseAdmin.firestore.FieldValue.arrayUnion(newMessage),
      // num_answers: firebaseAdmin.firestore.FieldValue.increment(1),
      messages: currentMessages,
      num_answers: currentMessages.length,
    };
  }

  // return DB.collection(`users/${uid}/projects/${pid}/conversations`)
  //   .doc(cid)
  //   .update(
  //     (payload = {
  //       messages: firebaseAdmin.firestore.FieldValue.arrayUnion(newMessage),
  //     })
  //   );

  const ref = doc(DB, `users/${uid}/projects/${pid}/conversations`, cid);
  await setDoc(ref, payload, { merge: true });

  return newMessage;
};
