// @mui
// @intl
import { createIntl, createIntlCache } from '@formatjs/intl';
import { deDE, enUS, itIT } from '@mui/material/locale';

// routes
import { PATH_DASHBOARD } from './routes/paths';

const cache = createIntlCache();

export const intl = {
  'it-IT': createIntl(
    {
      locale: 'it-IT',
      messages: {},
    },
    cache
  ),
  'en-US': createIntl(
    {
      locale: 'en-US',
      messages: {},
    },
    cache
  ),
  'de-DE': createIntl(
    {
      locale: 'de-DE',
      messages: {},
    },
    cache
  ),
};

// API

export const SOFIA_API = process.env.REACT_APP_SOFIA_API;

export const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

export const PUBLIC_URL = process.env.REACT_APP_SOFIA_PUBLIC_URL;

export const BABEL_ENV = process.env.BABEL_ENV;

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
};

export const STRIPE_PRICING_TABLE_ID = process.env.REACT_APP_STRIPE_PRICING_TABLE_ID;
export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.project.list;
// export const PATH_AFTER_LOGIN = PATH_DASHBOARD.user.checkout;

// LAYOUT

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you change settings.

export const defaultSettings = {
  themeMode: 'dark',
  themeDirection: 'ltr',
  themeContrast: 'bold',
  themeLayout: 'horizontal',
  themeColorPresets: 'purple',
  themeStretch: false,
};

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.

export const allLangs = [
  {
    label: 'Italiano',
    value: 'it',
    systemValue: itIT,
    icon: '/assets/icons/flags/ic_flag_it.svg',
  },
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_en.svg',
  },
  {
    label: 'Deutsch',
    value: 'de',
    systemValue: deDE,
    icon: '/assets/icons/flags/ic_flag_de.svg',
  },
];

export const defaultLang = allLangs[0]; // Italian
