const de = {
  demo: {
    title: `Deutsch`,
    introduction: ``,
  },
  docs: {
    hi: `Hallo`,
    description_z: `Brauchen Sie Hilfe?\n Bitte lesen Sie unsere`,
    documentation_z: `doku`,
    description: `Brauchen Sie Hilfe?\n Bitte kontaktieren Sie unser`,
    documentation: `team!`,
  },
  sofia_your_business_oracle: `sοφία, dein Business-Orakel`,
  enhance_your_knowledge_base: `Erweitern Sie Ihre Wissensdatenbank! Abfrage von Unternehmensdokumenten und -daten mit der Leistung von ChatGPT.`,
  export_conversation: `Konversation export`,
  dashboard: `Dashboard`,
  app: `app`,
  user: `user`,
  list: `list`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `erstellen`,
  kanban: `kanban`,
  general: `general`,
  banking: `bankwesen`,
  booking: `buchung`,
  profile: `Profil`,
  account: `Konto`,
  product: `Produkt`,
  invoice: `Rechnung`,
  details: `Details`,
  checkout: `checkout`,
  calendar: `Kalender`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `projects`,
  menu_level_1: `menu level 1`,
  menu_level_2a: `menu level 2a`,
  menu_level_2b: `menu level 2b`,
  menu_level_3a: `menu level 3a`,
  menu_level_3b: `menu level 3b`,
  menu_level_4a: `menu level 4a`,
  menu_level_4b: `menu level 4b`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  item_external_link: `item external link`,
  description: `Beschreibung`,
  other_cases: `andere Fälle`,
  item_by_roles: `Artikel nach Rollen`,
  only_admin_can_see_this_item: `Nur der Administrator kann dieses Element sehen`,
  name: `Name`,
  created_at: `Erstellt am`,
  updated_at: `Aktualisiert am`,
  size: `Größe`,
  type: `Typ`,
  status: `Status`,
  file: `Dateien`,
  upload: `Upload`,
  download: `Download`,
  delete: `Löschen`,
  copy_link: `Link kopieren`,
  remove_all: `Alles entfernen`,
  go_back: `Zurückgehen`,
  not_found: 'Nicht gefunden',
  no_results_found_for: 'Keine Ergebnisse gefunden für',
  try_checking_for_typos_or_using_complete_words: 'Versuchen Sie, nach Tippfehlern zu suchen oder vollständige Wörter zu verwenden.',
  please_enter_keywords: 'Bitte geben Sie Schlüsselwörter ein.',
  delete_conversation: `Konversation löschen`,
  tts_voice_test: `Hallo, ich bin dein AI-Assistent. Wie kann ich Ihnen helfen?`,
  chats: {
    title: `Chats`,
    search_contacts: `Suche Chats...`,
    recipients: `Bots`,
    send_to: `Senden an`,
    type_message: `Geben Sie eine Nachricht ein`,
    search_available_questions: `Suche verfügbare Fragen...`,
    how_can_i_help_you: `Hallo, wie kann ich  dir helfen?`,
    conversations: `Konversationen`,
    create_new_conversation_on_project: `Neues Gespräch im Projekt erstellen`,
    new_conversation_created_successfully: `Neue Konversation erfolgreich erstellt`,
    new_conversation_creation_failed: `Neue Konversationserstellung fehlgeschlagen`,
  },
  users: {
    accounts: {
      settings: `Kontoeinstellungen`,
    },
  },
  files: {
    exclude_from_indexes: `Aus Indizes ausschließen`,
    drop_or_select_files: `Dateien fallen lassen oder auswählen`,
    drop_files_here_or_click: `Dateien hier ablegen oder anklicken`,
    browse: `Durchsuchen`,
    through_your_machine: `Durch Ihren Rechner`,
    upload_files: `Dateien hochladen`,
    search_files: `Suchen...`,
    drop_files_here_to_upload: `Dateien zum Hochladen hier ablegen`,
    files_size: `Dateigröße`,
    files_progress: `Fortschritt der Dateien`,
    no_files: `Keine Dateien`,
    delete: `Dateien löschen`,
    preview: `Datei-Vorschau`,
    failed: `Files failed`,
    deleted: `Dateien gelöscht`,
    approved: `Dateien genehmigt`,
    rejected: `Dateien abgelehnt`,
    pending: `Files pending`,
    processed: `Dateien verarbeitet`,
    uploading: `Dateien werden hochgeladen`,
    limit: `Dateilimit`,
    cancelled: `Dateien storniert`,
    type_not_allowed: `Dateityp nicht erlaubt`,
    max_size: `Dateien maximale Größe`,
    max_files: `Dateien max Dateien`,
    required: `Bitte wählen Sie eine Datei`,
    uploaded_successfully: `Dateien erfolgreich hochgeladen`,
    upload_started: `Datei-Upload gestartet`,
    upload_failed: `Dateiupload fehlgeschlagen`,
    upload_cancelled: `Dateien-Upload abgebrochen`,
    deleted_successfully: `Dateien erfolgreich gelöscht`,
    deletion_started: `Dateienlöschung gestartet`,
    deletetion_failed: `Files deletetion failed`,
  },
  projects: {
    save: `Projekt speichern`,
    saved_successfully: `Projekt erfolgreich gespeichert`,
    save_failed: `Projekt speichern fehlgeschlagen`,
    delete: `Projekt löschen`,
    deleted_successfully: `Projekt erfolgreich gelöscht`,
    delete_failed: `Projekt löschen fehlgeschlagen`,
    create: `Projekt erstellen`,
    is_active: `Ist aktiv`,
    projects_status: `Projektestatus`,
    projects_available: `Verfügbare Projekte`,
    projects_used: `Verwendete Projekte`,
    project_name_is_required: `Projektname ist erforderlich`,
    project_description_is_required: `Projektbeschreibung ist erforderlich`,
    use_with_chatgpt_plugin: `Mit ChatGPT-Plugin verwenden`,
    questions_master_prompt: `Fragen Master Prompt`,
    answers_master_prompt: `Antworten Master Prompt`,
    query_top_k: `Query Top K antworten`,
    chunks: `Chunks`,
    chunk_questions: `N° Fragen zu erstellen pro Chunk`,
    chunk_size: `Chunk-Größe`,
    chunk_overlap: `Chunk-Überlappung`,
    temperature_questions: `Fragen-Temperatur`,
    temperature_answers: `Antworten-Temperatur`,
    max_tokens_answers: `Maximale Token-Antwort`,
    model_name_answers: `Antworten-Modell`,
    model_name_questions: `Fragen-Modell`,
    embedding_model: `Enbedding-Modell`,
    hybrid_convex_alpha: `Hybrid Convex Scale Alpha`,
    qa_language: `Projektsprache`,
    tts_settings: `Text-to-Speech Einstellungen`,
    voice_for_questions: `Stimme für Fragen`,
    voice_for_answers: `Stimme für Antworten`,
    conversation_deleted_successfully: `Konversation erfolgreich gelöscht`,
    delete_conversation_failed: `Konversation löschen fehlgeschlagen`,
    telephony_app_url: `URL App Telefonia`,
    voice_app_url: `URL App Voce`,
    index_has_vectors: `Index hat Vektoren`,
    index_has_no_vectors: `Index hat keine Vektoren`,
    error_checking_vectors: `Fehler beim Überprüfen der Vektoren`,
    save_version: `Version speichern`,
    version_saved_successfully: `Version erfolgreich gespeichert`,
    version_save_failed: `Version speichern fehlgeschlagen`,
    select_version: `Version auswählen`,
    restore_version: `Version wiederherstellen`,
    version_restored_successfully: `Version erfolgreich wiederhergestellt`,
    version_restore_failed: `Version wiederherstellen fehlgeschlagen`,
    rename_version: `Version umbenennen`,
    new_version_name: `Neuer Versionsname`,
    version_renamed_successfully: `Version erfolgreich umbenannt`,
    version_rename_failed: `Version umbenennen fehlgeschlagen`,
  },
  login: {
    title: `Anmeldung`,
    dont_have_an_account: `Haben Sie kein Konto?`,
    sign_up: `Anmelden`,
    already_have_an_account: `Bereits ein Konto haben?`,
    log_in: `Anmelden`,
    reset_password: `Passwort zurücksetzen`,
    forgot_password: `Passwort vergessen?`,
    email_address: `E-Mail-Adresse`,
    password: `Passwort`,
    get_started: `Gestartet`,
    loading: `Loading...`,
    hi_welcome_back: `Hallo, Willkommen zurück`,
    enter_your_details_below: `Geben Sie Ihre Daten unten ein`,
    use_email: `E-Mail benutzen`,
    dont_have_an_account_sign_up: `Don't have an account? Registrieren Sie sich`,
    by_clicking_sign_up_you_agree_to: `Durch Anklicken von "Sign up" stimmen Sie zu`,
    our_terms_of_service_and_privacy_policy: `unseren Nutzungsbedingungen und Datenschutzrichtlinien`,
    welcome_to: `Willkommen`,
    sign_in: `Anmelden`,
    login_to_your_account: `Anmelden bei Ihrem Konto`,
    request_sent_success: `Anfrage erfolgreich gesendet`,
    remember_me: `Erinnere mich`,
    log_in_with_google: `Mit Google anmelden`,
    or: `oder`,
  },
  register: {
    title: `Registrieren`,
    already_have_an_account: `Sie haben bereits ein Konto?`,
    log_in: `Anmelden`,
    manage_the_job_more_effectively_with: `Verwalte den Job effektiver mit`,
    get_started_absolutely_free: `Starten Sie absolut kostenlos`,
    free_forever_no_credit_card_needed: `Für immer kostenlos. Keine Kreditkarte erforderlich`,
    by_registering_i_agree_to_our: `By registering, I agree to your`,
    and: `und`,
    terms_of_service: `Dienstleistungsbedingungen`,
    already_have_an_account_login: `Sie haben bereits ein Konto? Anmelden`,
    password_rules: `Das Passwort muss mindestens 8 Zeichen lang sein und mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten.`,
  },
  new_password: {
    title: `Neues Passwort`,
    request_sent_successfully: `Anfrage erfolgreich gesendet!`,
    weve_sent_a_6_digit_confirmation_email_to_your_email: `Wir haben eine 6-stellige Bestätigungs-E-Mail an Ihre E-Mail gesendet.`,
    please_enter_the_code_in_below_box_to_verify_your_email: `Bitte geben Sie den Code in das unten stehende Feld ein, um Ihre E-Mail zu verifizieren.`,
    dont_have_a_code: `Haben Sie keinen Code?`,
    resend_code: `Code erneut senden`,
    code_is_required: `Code ist erforderlich`,
    code_must_be_a_6_digit_number: `Code muss eine 6-stellige Zahl sein`,
  },
  reset_password: {
    title: `Passwort zurücksetzen`,
    forgot_your_password: `Vergessenes Passwort?`,
    please_enter_the_email_address_associated_with_your_account: `Bitte geben Sie die mit Ihrem Konto verknüpfte E-Mail-Adresse ein, und wir senden Ihnen einen Link zum Zurücksetzen Ihres Passworts per E-Mail zu`,
    back: `Zurück`,
  },
  nav_items: {
    dashboard: `Dashboard`,
    management: `Management`,
    users: `Users`,
    user: `Benutzer`,
    account: `Account`,
    settings: `Einstellungen`,
    logout: `Logout`,
    login: `Login`,
    register: `Registrieren`,
    new_password: `Neues Passwort`,
    reset_password: `Passwort zurücksetzen`,
    profile: `Profil`,
    edit_profile: `Profil bearbeiten`,
    change_password: `Passwort ändern`,
    user_management: `Benutzerverwaltung`,
    user_list: `Benutzerliste`,
    user_edit: `Benutzer bearbeiten`,
    user_create: `Benutzer anlegen`,
    user_detail: `Benutzer Detail`,
    user_delete: `Benutzer löschen`,
    chat: `Chat`,
    mail: `Mail`,
    subscription: `Abonnement`,
    files: `Files`,
    invoices: `Invoices`,
    projects: `Projekte`,
    questions: `Fragen`,
    conversations: `Konversationen`,
  },
  breadcrumbs: {
    home: `Home`,
    dashboard: `Dashboard`,
    account: `Konto`,
    upload_files: `Dateien hochladen`,
    manage_files: `Dateien verwalten`,
    data: `Daten`,
    projects: `Projekte`,
    choose_your_plan: `Wählen Sie Ihren Plan`,
    payment: `Zahlung`,
    checkout: `Checkout`,
    subscription: `Abonnement`,
    billing_address: `Rechnungsadresse`,
    done: `Done`,
    new_project: `Neues Projekt`,
    edit_project: `Bearbeiten`,
    list: `Liste`,
  },
  current_subscription_info: `Aktuelle Abo-Infos`,
  you_are_signed_in_as: `Sie sind angemeldet als `,
  check_your_subscribed_plan_here: `Prüfen Sie Ihren abonnierten Plan hier`,
  settings: `Einstellungen`,
  your_plan_status_is: `Ihr Planstatus ist`,
  active: `Aktiv`,
  expired: `Expired`,
  inactive: `Inaktiv`,
  current_plan: `Aktueller Plan`,
  plan: `Plan`,
  price: `Preis`,
  next_payment: `Nächste Zahlung`,
  properties: `Eigenschaften`,
  modified: `Geändert`,
  subscribe: `Abonnieren`,
  buy_now: `Jetzt kaufen`,
  start_free_trial: `Kostenlose Testversion starten`,
  cancel_subscription: `Abonnement kündigen`,
  your_current_plan: `Dein aktueller Plan`,
  plan_activated_successfully: `Plan erfolgreich aktiviert`,
  plan_deactivated_successfully: `Plan erfolgreich deaktiviert`,
  plan_activation_failed: `Planaktivierung fehlgeschlagen`,
  checkout_process_started_plase_wait: `Checkout-Prozess gestartet. Bitte warten...`,
  popular: `Beliebt`,
  save_changes: `Änderungen speichern`,
  updgrade_plan: `Upgrade plan`,
  available: `Verfügbar`,
  used: `Verwendet`,
  used_f: `Verwendet`,
  pages: `Seiten`,
  words: `Wörter`,
  queries: `Abfragen`,
  answers: `Antworten`,
  included_in_plan: `Im Plan enthalten`,
  included_in_plan_f: `Im Plan enthalten`,
  plan_limit_reached: `Planlimit erreicht`,
  number_of_projects_limit_reached: `Anzahl der Projekte erreicht. \nDu kannst aber weiterhin Dateien hochladen und Fragen an Sofia GPT stellen.`,
  welcome_back: `Willkommen zurück \n`,
  upgrade_your_plan_to_create_more_projects: `Plan upgraden, um mehr Projekte zu erstellen`,
  create_new_projects_upload_your_files_and_ask_questions_to_sofia_gpt: `Erstellen Sie neue Projekte, laden Sie Ihre Dateien hoch und stellen Sie Sofia GPT Fragen`,
  messages: `Nachrichten`,
  ai_faqs: `AI FAQs`,
  bot_answers: `Bot Antworten`,
  no_files_uploaded: `Keine Dateien hochgeladen`,
  downgrade_plan: `Downgrade plan`,
  email_must_be_a_valid_email_address: `Email muss eine gültige E-Mail-Adresse sein`,
  email_is_required: `Email ist erforderlich`,
  password_is_required: `Password ist erforderlich`,
  first_name_is_required: `Name ist erforderlich`,
  last_name_is_required: `Nachname ist erforderlich`,
  email_address: `Email`,
  first_name: `Name`,
  last_name: `Nachname`,
  password: `Passwort`,
  this_email_is_invalid: `Diese E-Mail ist ungültig`,
  this_account_has_been_deactivated_or_deleted: `Dieses Konto wurde deaktiviert oder gelöscht`,
  this_user_was_not_found_have_you_created_your_account: `Dieser Benutzer wurde nicht gefunden: Haben Sie Ihr Konto erstellt?`,
  this_email_was_already_used_for_another_account: `Diese E-Mail wurde bereits für ein anderes Konto verwendet`,
  service_seems_to_be_unavailable_please_try_again_later: `Der Service scheint nicht verfügbar zu sein. Bitte versuchen Sie es später erneut.`,
  please_log_out_and_log_in_again_to_verify_your_identify_then_change_your_email: `Bitte melden Sie sich ab und melden Sie sich erneut an, um Ihre Identität zu überprüfen, und ändern Sie dann Ihre E-Mail.`,
  that_s_a_lot_of_unsuccessful_login_attempts_wait_a_bit_before_trying_again_or_reset_your_password: `Das wären eine Menge erfolgloser Anmeldeversuche! Warten Sie ein wenig, bevor Sie es erneut versuchen, oder setzen Sie Ihr Passwort zurück.`,
  the_password_is_invalid: `Das Passwort ist ungültig`,
  this_password_is_too_weak_please_use_more_letters_and_digits: `Dieses Passwort ist zu schwach. Bitte verwenden Sie mehr Buchstaben und Ziffern.`,
  confirm_password_is_required: `Bestätigung des Passworts ist erforderlich`,
  password_does_not_match: `Passwort stimmt nicht überein`,
  confirm_password: `Passwort bestätigen`,
  available_only_on_enterprise_plan: `Nur im Enterprise-Plan verfügbar`,
  refresh_token: `Token aktualisieren`,
  token_updated_successfully: `Token erfolgreich aktualisiert`,
  token_update_failed: `Token-Update fehlgeschlagen`,
  generate_token: `Token generieren`,
  tokens: `Tokens`,
  questions: {
    list_title: `FAQ: Liste`,
    list_heading: `FAQ Liste`,
    chat_name: `Chat`,
    faqs_name: `FAQs`,
    new_question_action: `Neue Frage`,
    label_question: `Frage`,
    label_questions: `Fragen`,
    label_updated_at: `Aktualisiert am`,
    label_topic: `Thema`,
    start_date: `Startdatum`,
    end_date: `Enddatum`,
    search_placeholder: `Frage oder Thema suchen...`,
    delete: `Löschen`,
    edit: `Bearbeiten`,
    required: `Frage ist erforderlich`,
    updated_successfully: `FAQ erfolgreich gespeichert`,
    update_failed: `Speichern fehlgeschlagen`,
    deleted_successfully: `FAQ erfolgreich gelöscht`,
    delete_failed: `Löschen fehlgeschlagen`,
    asked_successfully: `Antwort von GPT erhalten`,
    ask_failed: `Fragen an GPT fehlgeschlagen`,
    label_answer: `Antwort`,
    save: `Speichern`,
    generate_answer: `Automatische Antwort generieren`,
    export_to_csv: `Exportieren in CSV`,
    export_to_excel: `Exportieren in Excel`,
    faqs_deletion_started: `FAQs löschen gestartet.`,
    faqs_deletion_successfully: `FAQs erfolgreich gelöscht`,
    faqs_deletetion_failed: `FAQs löschen fehlgeschlagen`,
    faqs_batch_create_started: `FAQs Batch erstellen gestartet`,
    faqs_batch_create_successfully: `FAQs Batch erfolgreich erstellt`,
    faqs_batch_create_failed: `FAQs Batch erstellen fehlgeschlagen`,
    faqs_batch_create_answers_started: `Faqs batch create answers started`,
    faqs_batch_create_answers_successfully: `Faqs batch create answers successfully`,
    faqs_batch_create_answers_failed: `Faqs batch create answers failed`,
    no_questions_selected: `No questions selected`,
  },
  phone_number: `Telefonnummer`,
  address: `Adresse`,
  country: `Land`,
  state: `Bundesland`,
  city: `Stadt`,
  zip_code: `Postleitzahl`,
  about: `About`,
};

export default de;
