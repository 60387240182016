export const _embeddingModels = [
  { value: 'text-embedding-ada-002', label: 'OpenAI Ada 002' },
  { value: 'text-embedding-3-small', label: 'OpenAI Text Embedding 3 Small' },
  { value: 'text-embedding-3-large', label: 'OpenAI Text Embedding 3 Large' },
  { value: 'voyage-large-2-instruct', label: 'Voyage Large 2 Instruct' },
  { value: 'voyage-finance-2', label: 'Voyage Finance 2' },
  { value: 'voyage-multilingual-2', label: 'Voyage Multilingual 2' },
  { value: 'voyage-law-2', label: 'Voyage Law 2' },
  { value: 'voyage-code-2', label: 'Voyage Code 2' },
  { value: 'voyage-large-2', label: 'Voyage Large 2' },
  { value: 'voyage-2', label: 'Voyage 2' },
];
