export const _languages = [
  {
    code: 'it',
    label: 'Italiano',
    flag: '🇮🇹',
    icon: '/assets/icons/flags/ic_flag_it.svg',
  },
  {
    code: 'en',
    label: 'English',
    flag: '🇺🇸',
    icon: '/assets/icons/flags/ic_flag_us.svg',
  },
  {
    code: 'de',
    label: 'Deutsch',
    flag: '🇩🇪',
    icon: '/assets/icons/flags/ic_flag_de.svg',
  },
  {
    code: 'es',
    label: 'Español',
    flag: '🇪🇸',
    icon: '/assets/icons/flags/ic_flag_es.svg',
  },
  {
    code: 'fr',
    label: 'Français',
    flag: '🇫🇷',
    icon: '/assets/icons/flags/ic_flag_fr.svg',
  },
  {
    code: 'pt',
    label: 'Português',
    flag: '🇵🇹',
    icon: '/assets/icons/flags/ic_flag_pt.svg',
  },
  {
    code: 'nl',
    label: 'Nederlands',
    flag: '🇳🇱',
    icon: '/assets/icons/flags/ic_flag_nl.svg',
  },
];
